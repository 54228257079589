.field:not(:last-child) {
  margin-bottom: 1rem !important;
}

.field {
  ion-label {
    padding-left: 0.2rem;
    padding-right: 0.5rem;
    color: #0d0d0d;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    font-weight: 700;

    p {
      color: #ffb661;
    }
  }
}
.customInput {
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 8px;
  margin-top: 0.25rem;
  transition: all 0.2s linear;
  background-color: rgba(34, 36, 96, 0.06);
  color: #000000;
}
.customInputChild {
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 8px;
  margin-top: 0.25rem;
  transition: all 0.2s linear;
  background-color: rgba(34, 36, 96, 0.06);
  color: #000000;
}

.showPasswordCheckbox {
  --border-color: black;

  &:focus-within {
    border: 1px solid black;
  }
}

.showPasswordContainer {
  margin-top: 5px;
  display: flex;
  justify-content: start;
  gap: 5px;
  align-items: center;
}

.showPasswordLabel {
  color: black;
  font-size: 0.9em;
}
