.headingSection {
  align-items: flex-start;
  ion-card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #c6302c !important;
    display: flex;
  }
  .subheading {
    display: flex;
    align-items: center;
  }
  h5 {
    color: #222460;
    font-size: 28px;
    font-weight: 700;
  }
  h6 {
    color: #40414d;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    padding-top: 2px;
  }
}
.gridview {
  margin-left: inherit;
  margin-top: 20px;
}
