.headarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  padding: 10px;
  height: 75px;
  background-color: #eeeef2;
  z-index: 1;
  position: relative;

  .notify {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    margin-left: auto;
  }
  .notify_active {
    width: 60px;
    height: 60px;
    background-color: var(--ion-color-danger);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    color: #ffffff;
    -webkit-animation: shake 400ms ease-in-out;
    animation: shake 400ms ease-in-out;
    margin-left: auto;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    12.5% {
      transform: translateX(-6px) rotateY(-5deg);
    }
    37.5% {
      transform: translateX(5px) rotateY(4deg);
    }
    62.5% {
      transform: translateX(-3px) rotateY(-2deg);
    }
    87.5% {
      transform: translateX(2px) rotateY(1deg);
    }
    100% {
      transform: translateX(0);
    }
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translateX(0);
    }
    12.5% {
      -webkit-transform: translateX(-6px) rotateY(-5deg);
    }
    37.5% {
      -webkit-transform: translateX(5px) rotateY(4deg);
    }
    62.5% {
      -webkit-transform: translateX(-3px) rotateY(-2deg);
    }
    87.5% {
      -webkit-transform: translateX(2px) rotateY(1deg);
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }

  .imagedp {
    display: flex;
    justify-content: center;
    align-items: center;

    .userdp {
      border: 3px solid #ffffff;
      height: 60px;
      width: 60px;
    }

    .username {
      font-family: 'Poppins';
      margin-left: 10px;
      color: #222460;
      font-weight: 600;
    }
  }
}
