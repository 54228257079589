.reset {
  .customInput {
    --padding-bottom: 1rem;
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    border-radius: 8px;
    margin-top: 0.25rem;
    transition: all 0.2s linear;
    background-color: rgba(34, 36, 96, 0.06);
    color: #000000;
  }
  ion-label {
    color: #000000;
  }
}

.showPasswordCheckbox {
  --border-color: black;

  &:focus-within {
    border: 1px solid black;
  }
}

.showPasswordContainer {
  margin-top: 5px;
  margin-left: 10px;
  display: flex;
  justify-content: start;
  gap: 5px;
  align-items: center;
}

.showPasswordLabel {
  color: black;
  font-size: 0.9em;
}
