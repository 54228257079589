.intresticon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000;
  fill: green;
}
.iconname {
  color: black;
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 3px;
  font-weight: 400;
  text-align: center;
}
.iconwrapper {
  width: 100px;
  margin: 5px 15px 15px 0px;
}
.listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.customButton {
  --border-radius: 5px !important;
  --background: #222460;
  --background-activated: #222460;
  --background-focused: #222460;
  margin-top: 2rem;

  &:focus {
    border: 1px solid white;
    border-radius: 5px;
    outline: 1px solid black;
  }
}
.intresticonsel {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #222460;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
