.deetswrap {
  display: flex;
  flex-direction: column;
  ion-item {
    background-color: #ffff;
  }
  .deetlist {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 63px;
    padding: 0px 15px;
  }
  .container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    border-radius: 8px;
    background-color: #f1f1f1;
    color: #000000;

    &:focus-within {
      border: 1px solid white;
      border-radius: 5px;
      outline: 1px solid black;
    }

    .customTarea {
      --padding-bottom: 1rem;
      --padding-top: 1rem;
      --padding-start: 1rem;
      --padding-end: 1rem;
      border-radius: 8px;
      margin-top: 0.25rem;
      transition: all 0.2s linear;
      color: #000000;
      margin-left: 2rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
      background-color: #ffffff;
    }
    ion-checkbox {
      --checkmark-color: #ffffff;
      --background: #ffffff;
      --border-color: #000000;
      --background-checked: #3b6e1d;
      --border-color-checked: #3b6e1d;
    }
    .textarea {
      background-color: rgba(255, 255, 255, 0.09);
    }
  }

  .checkboxLabel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.customButton {
  --border-radius: 5px !important;
  --background: #222460;
  --background-activated: #222460;
  --background-focused: #222460;
  margin-top: 2rem;

  &:focus {
    border: 1px solid white;
    border-radius: 5px;
    outline: 1px solid black;
  }
}

.centered_deets_spinner {
  margin: auto;
  border: 0px solid white;

  ion-label {
    color: black !important;
  }
}
