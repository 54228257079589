.reactselect {
  background-color: rgba(210, 210, 219, 0.09);
  border: 0px solid rgba(210, 210, 219, 0.09);
  margin-top: 8px;
  z-index: 1000 !important;
}
.react-select__menu {
  z-index: 1000 !important;
}
.settings {
  color: #eeeef2;

  .content {
    background-color: #eeeef2;
    .headerwrap {
      --background: #eeeef2;
      padding-bottom: 15px;
      margin-top: 50px;
    }
    ion-item {
      --padding-start: 16px;
      --inner-padding-end: 5px;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading {
        color: #222460;
        font-size: 28px;
        font-weight: 700;
      }
      p {
        color: #0d0d0d;
        font-size: 14px;
        padding-bottom: 2px;
      }
      ion-icon {
        font-size: 24px;
        color: #000000;
        padding-left: 5px;
        cursor: pointer;
      }
    }
    .gridlist {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      background-color: #ffffff;
      border-radius: 8px;
      min-height: 100px;
      .distanceHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .distanceLabel {
        height: unset !important;
        margin-bottom: unset !important;
      }
      .ageRange {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ageSection {
          display: flex;
          align-items: center;
          color: #000000;
          margin-top: 10px;
          .customInput {
            max-width: 100px;
            margin-left: 15px;
          }
        }
        .ageLabel {
          color: #222460;
          min-width: 140px;
          opacity: 0.4;
        }
      }
      // input[type=number]:after {
      //     content: "miles";
      // }
      .infotext {
        color: #555555;
        background: #eeeeee;
        padding: 10px !important;
        margin: 0px;
        border-radius: 5px;
        font-size: small;
      }
      .dropdown {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        .minAgeError {
          color: red;
        }
        ion-label {
          color: #000000;
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 16px;
          height: 30px;
        }
        .react-select__control {
          margin-top: 5px;
          padding-top: 5px;
        }
        .rangeval {
          font-size: 14px;
          float: right;
          color: #222460;
          font-weight: 500;
          opacity: 0.6;
          padding-right: 5px;
        }
      }
      ion-item {
        --inner-padding-end: 0px;
      }
      .extlink {
        --background: #eeeef2;
        align-items: center;
        border-radius: 8px;
        background-color: #eeeef2;
        color: #0d0d0d;
        font-weight: 500;
        ion-icon {
          width: 15px;
        }
      }
      .extlinkInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .radiobtn {
        background-color: #ffffff;
        ion-label {
          font-size: 16px;
          color: #000000;
          font-weight: 500;
        }
      }
      .borderline {
        border-bottom: 1px solid #ebebeb;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 10px;
      }
    }
    .title {
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      padding-left: 10px;
    }
  }
  ion-content {
    --background: #eeeef2;
  }
  ion-range {
    --bar-background: #ebebeb;
    --bar-background-active: #ffb661;
    --bar-height: 4px;
    --bar-border-radius: 8px;
    --knob-background: #222460;
    --knob-size: 25px;
    --pin-background: #222460;
    --pin-color: #fff;
  }
  ion-toggle {
    --background: rgba(120, 120, 128, 0.16);
    --background-checked: #26a612;
    --handle-background: rgb(255, 255, 255);
    --handle-background-checked: rgb(255, 255, 255);
    height: 22px;
  }
  ion-input {
    --background: rgba(210, 210, 219, 0.09);
    --color: #000000;
    border-radius: 8px;
  }
  .abusebtn {
    ion-button {
      color: #0d0d0d;
      .button-native {
        border-color: #26a612 !important;
      }
    }
  }
  .locationicon {
    position: absolute;
    bottom: 21px;
    right: 24px;
  }
}
