.signupPage {
  padding-top: 2rem;
  ion-checkbox {
    --checkmark-color: #ffffff;
    --background: #ffffff;
    --border-color: #000000;
  }
  ion-toolbar {
    --background-color: #222460;
    --border-style: none;
    --border-color: transparent;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
  }
  ion-content {
    --ion-background-color: #ffffff;
  }
  ion-input {
    --background: #ffffff;
  }
  ion-label {
    color: #000000;
  }
  ion-card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #ecb888;
  }
  h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 28px;
    line-height: 42px;
    color: #222460;
    font-weight: 700;
  }
  .checks {
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 15px;
    label {
      margin-left: 5px;
    }
  }
  .optincheckbox {
    margin-left: 0px;
    margin-right: 10px;

    &:focus {
      border: 1px solid black;
    }
  }

  .optinlabel {
    display: flex;
    align-items: center;
    white-space: normal;
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins';

    a {
      padding-left: 5px;
    }
  }
  .optinLink {
    /* high z-index so that the links are clickable within a parent <label> */
    z-index: 999;
  }
}
.socialIcons {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}
.headingText {
  h5 {
    margin-top: 0.2rem;
  }
}
.customButton {
  --border-radius: 5px !important;
  --background: #222460;
  --background-activated: #222460;
  --background-focused: #222460;
  margin-top: 1rem;

  &:focus {
    border: 1px solid white;
    border-radius: 5px;
    outline: 1px solid black;
  }
}
.footerText {
  margin-top: 1rem;
  //position: fixed;
  left: 0;
  bottom: 10px;
  right: 0;
  font-size: 14px;
  p {
    color: #000000;
    ion-router-link {
      color: #0d0d0d;
      text-decoration: underline;
      margin-left: 2px;
    }
  }
}
.link {
  color: #0d0d0d;
  text-decoration: underline;
  margin-left: 2px;
  cursor: pointer;
}
.CancelBtn {
  background-color: gray !important;
  color: white !important;
  padding: 8px 20px !important;
}
.spam_info {
  color: #000000;
  background-color: #f2f2f2;
  padding: 0px 10px 5px 10px;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
}
