.termsPage {
  ion-checkbox {
    --checkmark-color: #ffffff;
    --background: #ffffff;
    --border-color: #000000;
  }

  h2 {
    color: #373836;
    font-size: 20px;
  }

  .acclabel {
    --border-width: 0;
    padding-top: 10px;
    border-radius: 6px;
    background: linear-gradient(282.61deg, #e3ecfd -0.49%, #ffffff 100%);

    &:focus-within {
      border: 1px solid black;
    }
  }

  .checkboxLabel {
    display: flex;
    gap: 5px;

    &:focus-within {
      border: 1px solid black;
    }
  }

  div[slot='content'] {
    // background-color: #E3ECFD;
    background: linear-gradient(282.61deg, #e3ecfd -0.49%, #ffffff 100%);
    color: #848484;
    font-size: 12px;
  }

  ion-accordion {
    background-color: #e3ecfd;
    // background: linear-gradient(282.61deg, #E3ECFD -0.49%, #FFFFFF 100%);
    color: #000000;
    margin-bottom: 10px;

    ion-icon {
      color: #000000;
    }
  }

  .head {
    color: #000000;
    font-size: 16px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;

    ion-icon {
      margin-left: 10px;
    }
  }

  .checks {
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 15px;

    label {
      margin-left: 10px;
    }
  }

  .agreebtn {
    --border-radius: 5px !important;
    --background: #222460;
    --background-activated: #222460;
    --background-focused: #222460;
    margin-top: 1rem;
    font-weight: 700;

    &:focus {
      border: 1px solid white;
      border-radius: 5px;
      outline: 1px solid black;
    }
  }

  .lines {
    height: 1px;
    margin: 5px;
    border: 1px solid #bdbdbd;
  }

  .viewdata {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 70%;
  }

  .textgrey {
    color: #848484;
    font-size: 12px;
    white-space: normal;
  }

  .policycontent {
    max-height: 200px;
    overflow: auto;
  }

  .optincheckbox {
    margin-left: 0px;
    margin-right: 10px;
  }

  .optinlabel {
    white-space: normal;
    font-size: 14px;
    color: #000000;
  }
}
