.swipeCard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 85%;
  box-shadow: 0px 2px 16px rgba(150, 150, 150, 0.1);
  border-radius: 20px;

  span {
    width: 100%;
  }

  .profileImg {
    height: calc(100% - 50px);

    object-fit: cover;
    width: 100% !important;
  }

  @media only screen and (min-width: 1025px) {
    .profileImg {
      object-fit: contain;
      width: 100% !important;
    }
  }
  .titleblock {
    display: flex;
    justify-content: space-between;

    .badgeicon {
      width: 20px;
      margin-top: -15px;
    }
  }

  .basicFrame {
    height: calc(100% - 180px);
  }
}

.footicon {
  display: flex;
  justify-content: space-between;
  color: #c6302c;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  height: 100px;

  .view {
    cursor: pointer;
    font-weight: 600;
    font-family: 'Poppins';
    color: #222460;
    font-size: 16px;
    text-decoration: none;
  }

  .image {
    cursor: pointer;
  }
}

.profileBtn {
  color: var(--ion-color-primary);
  font-weight: bold;
}

ion-content {
  background-color: #eaeaea;
}
