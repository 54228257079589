.pdetails {
  padding-left: 10px;
  padding-right: 10px;

  .subtitle {
    color: #0d0d0d;
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-weight: 700;
    margin-top: 10px;
    font-size: 20px;

    span {
      color: #ffb661;
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .profileName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;

    .instagram {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;

      :hover {
        cursor: pointer;
      }

      span {
        color: #000000 !important;
        font-size: 14px;
      }

      ion-img {
        height: 20px !important;
        width: 20px !important;
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .info {
        color: #000000;
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        .badgeimg {
          width: 19px;
          margin-left: 12px;
        }
      }

      p {
        color: #000000;
        font-size: 16px;
        display: inline;
        margin: 4px;
      }
    }
  }

  .about {
    h6 {
      font-size: 20px;
      font-weight: 600;
      color: #0d0d0d;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      color: #000000;
    }
  }

  .basics {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;

    h6 {
      font-size: 20px;
      font-weight: 600;
      color: #0d0d0d;
      margin-bottom: 0px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
    }
  }

  .basiclists {
    display: inline;
    flex-direction: column;
    width: 100%;
    padding: 0px;

    .items {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      font-size: 16px;
      font-weight: 400;

      .label {
        color: #0d0d0d;
        text-align: left;
        font-weight: bold;
      }

      .value {
        color: #000000;
      }
    }
  }

  .interest {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;

    .subtitle {
      padding-bottom: 10px;
    }

    ion-chip {
      background-color: #f3f3f3;
      color: #0d0d0d;

      ion-label {
        font-weight: 300;
      }

      ion-img {
        object-fit: scale-down;
        padding: 2px;
      }
    }

    h6 {
      font-size: 20px;
      font-weight: 600;
      color: #0d0d0d;
    }
  }

  .deets {
    padding-bottom: 10px;

    .subtitle {
      padding-bottom: 10px;
    }

    ion-chip {
      background-color: #f3f3f3;
      color: #0d0d0d;

      ion-label {
        font-weight: 300;
      }
    }

    h6 {
      font-size: 20px;
      font-weight: 600;
      color: #0d0d0d;
    }
  }

  .blockBtn {
    --color: #000000;
    border: 1px solid #ee0808;
    margin-top: 1rem;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 6px;
  }
  .requestBtn {
    --color: #000000;
    border: 1px solid #000000;
    margin-top: 1rem;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 6px;
  }
}

.alertConfirmBtn {
  background-color: var(--ion-color-primary) !important;
  color: #ffffff !important;
  padding: 6px 24px !important;
}

.alertCancelBtn {
  background-color: var(--ion-color-dark) !important;
  color: var(--ion-color-light) !important;
}
