ion-alert {
  --background: #e5e5e5;
  --backdrop-opacity: 0.6 !important;
}
.ion-overlay-wrapper {
  background-color: red;
}
.myclass .alert-wrapper {
  background: #ffffff;
  align-items: center;
  h2 {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}

.confirmdelete {
  background-color: red !important;
  border-radius: 5px !important;
  border: 0.5px solid #000000 !important;
  font-size: 12px !important;
  color: #ffffff !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.canceldelete {
  border-radius: 5px !important;
  border: 1px solid #000000 !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 12px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
