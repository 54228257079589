.faq {
  color: #eeeef2;

  .content {
    background-color: #eeeef2;

    .headerwrap {
      --background: #eeeef2;
      padding-bottom: 15px;
      margin-top: 50px;
    }

    ion-item {
      --padding-start: 16px;
      --inner-padding-end: 5px;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading {
        color: #222460;
        font-size: 28px;
        font-weight: 700;
      }

      .back {
        font-size: 14px;
        cursor: pointer;
      }
    }

    .gridlist {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      background-color: #eeeef2;
      color: #000000;
      min-height: 100px;

      .gridlistitem {
        padding: 10px;
        border-radius: 8px;
      }

      .question {
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
      }

      .icon {
        float: right;
      }

      .answer {
        margin: 10px;
        padding-bottom: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        padding-left: 8px;
      }
    }
  }

  ion-content {
    --background: #eeeef2;
  }
}
