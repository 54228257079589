.page {
  background-color: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);

  ion-header {
    background-color: #222460;
  }

  h4 {
    color: #222460;
    font-size: 28px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
  }

  ion-content {
    --background: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);
  }

  ion-searchbar {
    --border-radius: 10px;
    --background: white;
    --color: black;
    min-height: 5rem;
    padding: 1rem;
    padding-top: 0;
    margin-top: -1rem;
  }

  ion-list {
    background-color: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);
    margin: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }

  ion-card-subtitle {
    padding-left: 1.2rem;
    margin-top: 1.9rem;
    padding-bottom: 0.3rem;
    --color: white;
  }

  .results {
    --color: #5a55ca;
  }

  ion-footer {
    background-color: #0c38a1;
    padding: 1rem;

    ion-button {
      --background: #5a55ca;
      --background-focused: #6f6bbb;
      --background-activated: #6f6bbb;
      --padding-top: 1rem;
      --padding-bottom: 1rem;
      --padding-start: 0.75rem;
      --padding-end: 0.75rem;
      height: 3.5rem;
      font-weight: 700;
    }
  }
}

.notificationItem {
  --border-radius: 10px;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-bottom: 1rem;
  background-color: white;

  ion-item {
    background-color: #ffff;
    --padding-top: 0px;
    margin: 0px;
  }

  img {
    width: 3rem;
    border-radius: 50%;
    border: 2px solid #e7edfb;
  }

  ion-label {
    padding-left: 1.2rem;

    h2 {
      font-weight: 500;
      letter-spacing: -0.02rem;
      color: #000000;
      font-size: 14px;
    }

    p {
      letter-spacing: -0.03rem;
      color: #000000;
      font-size: 14px;
    }
  }

  ion-button {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 0.75rem;
    --padding-end: 0.75rem;
    margin-top: -0.2rem;
    color: #000000;
  }
}
.announceItem {
  --border-radius: 10px;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-bottom: 1rem;
  background-color: white;

  ion-item {
    background-color: #ffff;
    --padding-top: 0px;
    margin: 0px;
  }
  .iconsec {
    width: 80px;
  }
  img {
    width: 3rem;
    border-radius: 50%;
    border: 2px solid #e7edfb;
  }
  .exclaim {
    color: red;
  }
  .verifylink {
    font-size: 12px;
    color: #2c73de;
    text-decoration: underline;
    cursor: pointer;
  }
  ion-label {
    padding-left: 1.2rem;

    h3 {
      font-weight: 500;
      // letter-spacing: -0.02rem;
      color: #000000;
      // overflow: visible;
      // flex-wrap: wrap;
      font-size: 14px;
      white-space: break-spaces;
      opacity: 0.9;
      a {
      }
    }

    p {
      letter-spacing: -0.03rem;
      color: #000000;
      font-size: 14px;
    }
  }

  ion-button {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 0.75rem;
    --padding-end: 0.75rem;
    margin-top: -0.2rem;
    color: #000000;
  }
}
