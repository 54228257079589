.chat {
  ion-header {
    background-color: #222460;
    .user {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: center;
      align-items: center;
      ion-icon {
        color: #ffffff;
        padding-right: 1rem;
        font-weight: 700;
        font-size: 25px;
        cursor: pointer;
      }
      ion-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      img {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        border: 2px solid #e7edfb;
        object-fit: cover;
      }
      ion-label {
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        margin-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  ion-footer {
    background-color: #222460;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .typearea {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
    }
    .sendicon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #ffb661;
      align-items: center;
      margin-top: 7px;
      margin-left: 10px;
      cursor: pointer;
      ion-button {
        background-color: #ffb661;
      }
      ion-img {
        object-fit: none;
      }
    }
  }
}

.textarea {
  background: #ffffff;
  border-radius: 48px;
  padding: 4px 18px;
  color: #000000;
}

.messageList {
  height: calc(100vh - 75px - 95px);
}

.chat-left .rce-mbox {
  background-color: #ffbeae !important;
}
