.tutorial {
  width: 100%;
  height: 100%;

  .customButton {
    --border-radius: 5px !important;
    --background: #222460;
    --background-activated: #222460;
    --background-focused: #222460;
    margin-top: 1rem;

    &:focus {
      border: 1px solid white;
      border-radius: 5px;
      outline: 1px solid black;
    }
  }

  .imageSec {
    max-width: 322px;
    max-height: 347px;
    background: linear-gradient(180deg, #e1ebff 0%, rgba(237, 243, 255, 0.37) 100%);
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-bottom: 5px;

    ion-img.cellImage {
      height: 50px;
      margin-bottom: 10px;
    }

    ion-img {
      height: 220px;
      margin-bottom: 20px;
    }
  }

  .whiteImageSec {
    max-width: 322px;
    max-height: 347px;
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ion-img {
      height: 350px;
    }
  }

  .profilecard {
    max-height: 450px;
    margin-bottom: 20px;
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    margin-left: inherit;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;

    h5 {
      color: #222460;
      font-weight: 700;
      font-size: 24px;
    }

    h5.noMargin {
      margin: 0px;
    }

    p {
      color: #898989;
      font-weight: 400;
      font-size: 16px;
    }

    b {
      color: #de7700;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .tfooter {
    background-color: #ffffff;
    padding-bottom: 5px;
  }
}
