.matchContainer {
  display: flex;
  justify-content: center;
  margin-top: 3.5rem;

  .dottedborder {
    border-radius: 50%;
    border: 2px dotted var(--ion-color-primary);
    height: 10rem;
    width: 10rem;
    padding: 8px;
    position: relative;
    z-index: 1;
  }

  .mpWrap {
    background-color: var(--ion-color-warning);
    border-radius: 50%;
    padding: 3px;
    height: 100%;
  }

  .pWrap {
    height: 10rem;
    width: 10rem;
    background-color: var(--ion-color-primary);
    padding: 3px;
    border-radius: 50%;
    position: relative;
  }

  .pImg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    border-radius: 50%;
  }
}
