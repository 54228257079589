.togglehead {
  background-color: #222460;

  ion-img {
    object-fit: scale-down;
  }
}

.menulist {
  ion-icon {
    color: #000000;
  }

  .label {
    color: #000000;
    padding-left: 10px;
  }
}

.modelwrap {
  height: 90vh;
  padding-top: 20px;
  ion-textarea {
    background-color: red !important;
  }
}

.customtxt {
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 0px;
  margin-top: 2rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  transition: all 0.2s linear;
  background-color: rgba(210, 210, 219, 0.09);
  width: 100%;
  color: #000000;
  border: #ffffff;
  padding-left: 5px;
}

.contactbutton {
  button {
    color: #ffffff !important;
  }
}

.logoutConfirmBtn {
  background-color: var(--ion-color-primary) !important;
  color: #ffffff !important;
  padding: 6px 24px !important;
}

.logoutCancelBtn {
  background-color: var(--ion-color-dark) !important;
  color: var(--ion-color-light) !important;
}
.deletemsg {
  color: #000000;
  padding: 10px;
}
.note_label {
  color: #000000;
  padding-left: 5px;
}
.customnotetxt {
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 0px;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.5rem;
  transition: all 0.2s linear;
  background-color: rgba(210, 210, 219, 0.09);
  width: 100%;
  color: #000000;
  border: #ffffff;
  padding-left: 5px;
}
