.info {
  color: #0d0d0d;
}
.steps {
  color: #0d0d0d;
  display: flex;
  margin-bottom: 5px;
  .number {
    margin-right: 5px;
  }
  .point {
    width: fit-content;
  }
}
.upload_wrap {
  display: flex;
  margin-top: 10px;
  .imgsec {
    width: 155px !important;
    height: 155px;
    margin-right: 15px;
    border: 2px solid #222460;
    border-radius: 7px;
    .placeimage {
      width: 150px;
      height: 150px;
    }
    .uploadedimage {
      width: 150px;
      height: 150px;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .desc {
      color: #000000;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .action {
      color: #222460;
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      ion-img {
        height: 15px;
        margin-right: 5px;
      }
    }
  }
}
.customButton {
  margin-top: 20px;
}
.web_info {
  color: #0d0d0d;
}
.photo_content {
  margin-top: 20px;
}
