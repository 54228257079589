.search {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;

  .swipewrap {
    -webkit-overflow-scrolling: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding-top: 2rem;
    height: 100%;
    background-color: #eeeef2;
    position: relative;
  }
}
.gbanner {
  position: absolute;
  bottom: 5px;
  // background-color: red;
  // background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
}

.swipeCard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 90%;
  box-shadow: 0px 2px 16px rgba(150, 150, 150, 0.1);
  border-radius: 20px;

  span {
    width: 100%;
  }

  .profileImg {
    object-fit: cover;
    width: 100% !important;
  }
}

.footicon {
  display: flex;
  justify-content: space-between;
  color: #c6302c;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;

  .view {
    cursor: pointer;
    font-weight: 600;
    font-family: 'Poppins';
    color: #222460;
    font-size: 16px;
    text-decoration: none;
  }

  .image {
    cursor: pointer;
  }
}

.profileBtn {
  color: var(--ion-color-primary);
  font-weight: bold;
}

ion-content {
  background-color: #eaeaea;
}

.emptySearchMsgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
