.loginPage {
  padding-top: 2rem;

  ion-toolbar {
    --border-style: none;
    --border-color: transparent;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  ion-content {
    --ion-background-color: #ffffff;
  }
  ion-input {
    --background: #ffff;
  }
  ion-label {
    color: #000000;
  }
  ion-card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #ecb888;
  }
  on-activatable {
    color: #000000 !important;
    text-decoration: underline;
  }
  h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 28px;
    line-height: 42px;
    color: #222460;
    font-weight: 700;
  }
}
.field {
  display: flex;
  justify-content: center;
  ion-label {
    padding-left: 0.2rem;
    padding-right: 0.5rem;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    p {
      color: rgb(236, 149, 35);
    }
    span {
      font-size: 14px;
    }
  }
}
.socialIcons {
  padding-top: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  Ion-Img {
    width: 42px;
    margin-right: 10px;
    align-items: center;
  }
}
.socialIcon {
  display: flex;
  justify-content: center;
  text-align: center;
}
.headingText {
  h5 {
    margin-top: 0.2rem;
  }
}
.customButton {
  --border-radius: 5px !important;
  --background: #222460;
  --background-activated: #222460;
  --background-focused: #222460;
  margin-top: 1rem;

  &:focus {
    border: 1px solid white;
    border-radius: 5px;
    outline: 1px solid black;
  }
}

.footerText {
  margin-top: 1rem;
  font-size: 14px;
  p {
    color: #000000;
    ion-router-link {
      color: #0d0d0d;
      text-decoration: underline;
      margin-left: 2px;
    }
  }
}
.forgot_label {
  font-size: 14px;
  color: #222460;
  cursor: pointer;
  margin-left: 5px;
}
.CancelBtn {
  background-color: gray !important;
  color: white !important;
  padding: 8px 20px !important;
}
