.slide-main {
  width: 100%;
  height: 100%;
  border: 1px solid white;
}

.form-content {
  /* height : 84% */
}

.form-footer {
}

.a-slide {
  height: 100%;
  font-size: 30px;
  padding: 16px;
}

.noSwipingClass {
  margin: auto;
}

.footer-container {
  text-align: center;
}
.footer-button {
  width: 90%;
}
.error-message {
  color: red;
  margin-top: 5px;
}
.red {
  color: red;
}
.req_red {
  color: red;
  font-weight: 400;
}
