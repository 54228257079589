.imageSec {
  border-bottom-right-radius: 25%;
}

.image {
  z-index: 2;
}

.profilePhoto {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imgsecion {
  position: relative;
  height: 500px;
  object-fit: cover;
  .editlogo {
    color: #000000;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    background-color: #eeeef2;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
  }
  .location_label {
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    font-weight: 700;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .profileImg {
    height: 375px !important;
    width: 100vw !important;
    object-fit: cover !important;
    object-position: top !important;
  }

  @media only screen and (min-width: 1025px) {
    .profileImg {
      height: 50%;
      object-fit: contain !important;
      width: 100% !important;
    }
  }

  .description {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    text-align: left;
    padding-left: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0px;
    padding: 10px;
  }
}

.likeButtonGrp {
  position: absolute;
  bottom: -20px;
  width: 100%;
  z-index: 2;
}
.confirmBtn {
  background-color: var(--ion-color-primary) !important;
  color: #ffffff !important;
  padding: 6px 24px !important;
}

.cancelBtn {
  background-color: rgb(182, 182, 182) !important;
  color: var(--ion-color-light) !important;
  padding: 6px 24px !important;
}

.swipeButton {
  cursor: pointer;
}
