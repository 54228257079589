.moreabout {
  .checkbox {
    border: 1px solid var(--ion-color-medium-tint) !important;
    border-radius: 50%;
  }

  ion-toolbar {
    --background-color: #222460;
    --border-style: none;
    --border-color: transparent;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
  }

  ion-card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #ecb888;
  }

  h5 {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 28px;
    line-height: 42px;
    color: #222460;
    font-weight: 700;
  }

  .customInput {
    --padding-bottom: 1rem;
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    border-radius: 8px;
    margin-top: 0.25rem;
    transition: all 0.2s linear;
    background-color: rgba(210, 210, 219, 0.09);
    width: 100%;
    color: #000000;
    z-index: inherit;

    input[type='date']::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      color: transparent;
      background: transparent;
    }
  }

  ion-label {
    padding-left: 0.2rem;
    padding-right: 0.5rem;
    color: #0d0d0d;
    display: flex;
    align-content: center;
    align-items: center;
    font-weight: 700;
    margin-top: 10px;

    h6 {
      padding-left: 5px;
    }
  }

  .fullwidth {
    width: 100%;
  }
  .reqwarning {
    justify-content: space-between;
  }

  .delete {
    margin-left: 10px;
    cursor: pointer;
  }

  .uploadrow {
    display: flex;
    align-items: center;
    height: 74px;
    width: 100%;

    .uploadwrap {
      position: relative;
      margin-right: 10px;

      .upload {
        opacity: 0;
        position: absolute;
        max-width: 74px;
        height: 88px;
        cursor: pointer;
      }

      .imagebtn {
        max-width: 74px;
        max-height: 88px;
      }

      .loadingIndicator {
        margin: auto;
        width: 74px;
        height: 88px;
      }
    }
  }

  .customButton {
    --border-radius: 5px !important;
    --background: #222460;
    --background-activated: #222460;
    --background-focused: #222460;
    margin-top: 2rem;

    &:focus {
      border: 1px solid white;
      border-radius: 5px;
      outline: 1px solid black;
    }
  }

  .reactselect {
    background-color: rgba(210, 210, 219, 0.09);
    border: 0px solid rgba(210, 210, 219, 0.09);
    margin-top: 8px;
    z-index: 1 !important;
  }

  .reactselect2 {
    background-color: rgba(210, 210, 219, 0.09);
    border: 0px solid rgba(210, 210, 219, 0.09);
    margin-top: 8px;
    z-index: 2 !important;
  }

  .reactselect3 {
    background-color: rgba(210, 210, 219, 0.09);
    border: 0px solid rgba(210, 210, 219, 0.09);
    margin-top: 8px;
    z-index: 3 !important;
  }
}

.link {
  color: #0d0d0d;
  text-decoration: underline;
  margin-left: 2px;
  cursor: pointer;
}
