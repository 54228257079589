.basics {
  .customInput {
    --padding-bottom: 1rem;
    --padding-top: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    border-radius: 8px;
    margin-top: 0.25rem;
    transition: all 0.2s linear;
    background-color: rgba(210, 210, 219, 0.09);
    color: #000000;
  }
  ion-label {
    padding-left: 0.2rem;
    padding-right: 0.5rem;
    color: #0d0d0d;
    display: flex;
    align-content: center;
    align-items: center;
    font-weight: 700;
    margin-top: 10px;
  }
  .customButton {
    --border-radius: 5px !important;
    --background: #222460;
    --background-activated: #222460;
    --background-focused: #222460;
    margin-top: 2rem;

    &:focus {
      border: 1px solid white;
      border-radius: 5px;
      outline: 1px solid black;
    }
  }
  .react-select__menu {
    background-color: red;
  }
  .reqwarning {
    justify-content: space-between;
  }
  ion-toggle {
    --background: rgba(120, 120, 128, 0.16);
    --background-checked: #26a612;
    --handle-background: rgb(255, 255, 255);
    --handle-background-checked: rgb(255, 255, 255);
    height: 22px;
    z-index: 0;
  }
}

.labelDescription {
  color: #848484;
  font-size: 14px;
  white-space: normal;
  padding: 5px 0 0 3px;
  margin: 0;
}
