body,
h5,
h4,
ion-label,
p,
div {
  font-family: 'Poppins';
}

.ion-card-color {
  color: #1f1f1f;
}

ion-content {
  --ion-background-color: #ffffff;
}

ion-tab-bar,
ion-tab-button {
  background-color: #222460;
}

ion-tab-button:hover ion-label {
  font-weight: bold;
  color: #d1824e;
}

.tab-selected {
  color: #d1824e;
}

.react-select__control {
  background-color: rgba(210, 210, 219, 0.09) !important;
  border: 0px solid #ffffff !important;
}

.text-yellow {
  color: var(--ion-color-warning);
}

.text-red {
  color: #c6302c;
}

.text-dark {
  color: #222428;
}

.flex {
  display: flex;
}

.text-primary {
  color: var(--ion-color-primary);
}

.strong {
  font-weight: 900;
}

.semibold {
  font-weight: 500;
}

.title-lg {
  font-size: 2.5rem;
}

.text-2xl {
  font-size: 1.75rem;
}

.tab-icon {
  height: 25px;
}

.swiper {
  height: 100% !important;
}

ion-img.userphoto {
  --border-radius: 100% !important;
}

.chat-left .rce-mbox {
  background-color: #ffbeae !important;
}

.rce-container-mbox {
  margin: 20px;
}

.rce-mbox {
  box-shadow: none;
  min-width: 200px;
}

.rce-mbox.rce-mbox-right {
  background-color: #c8d2ff;
}

.rce-mbox-left-notch {
  fill: #ffbeae;
}

.rce-mbox-right-notch {
  fill: #c8d2ff;
}

.rce-container-input {
  flex: 1;
  min-width: auto;
}

.rce-container-input,
.rce-input.rce-input-textarea {
  border-radius: 48px;
}

.rce-input.rce-input-textarea {
  padding: 4px 24px;
}

.rce-input.rce-input-textarea::-webkit-scrollbar {
  display: none;
}

.pointer {
  cursor: pointer;
}

.blurredImg {
  filter: blur(4px);
}

.logOutAlert .alert-wrapper {
  background: #ffffff;
}

.logOutAlert .alert-title {
  color: #000;
}
.logOutAlert .alert-message {
  color: #000;
}
ion-item::part(native)::after {
  content: none;
}

ion-item::part(native)::after {
  z-index: 0;
  pointer-events: none;
}

:root {
  --swiper-theme-color: #232460;
}

/* .image-wrapper {
    height: calc(100% - 180px);
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    align-content: center;
} */

.milesaway {
  color: #aaaaaa;
  font-size: 14px;
}

.annouce_wrap .ql-toolbar {
  display: none;
}
.annouce_wrap .ql-container.ql-snow {
  border: 1px solid transparent !important;
}
