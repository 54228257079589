.page {
  background-color: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);

  ion-header {
    background-color: #222460;
  }

  h4 {
    color: #222460;
    font-size: 28px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
  }

  ion-content {
    --background: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);
  }

  ion-searchbar {
    --border-radius: 10px;
    --background: white;
    --color: black;
    min-height: 5rem;
    padding: 1rem;
    padding-top: 0;
    margin-top: -1rem;
  }

  ion-list {
    background-color: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);
    margin: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }
}

.mainContent {
  background-color: linear-gradient(166.48deg, #eeeef2 0%, rgba(234, 235, 255, 0) 97.95%);
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.matchItem {
  --border-radius: 10px;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-bottom: 1rem;
  background-color: white;
  margin: 0 1px 1px;
  cursor: pointer;

  &:active,
  &:focus {
    outline: 1px solid #222460;
  }

  ion-item {
    background-color: #ffff;
    --padding-top: 0px;
    margin: 0px;
  }

  ion-img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    --border-radius: 50px !important;
  }

  ion-img::part(image) {
    border-radius: 50%;
    border: 2px solid #e7edfb;
  }

  ion-label {
    padding-left: 1.2rem;

    h2 {
      font-weight: 500;
      letter-spacing: -0.02rem;
      padding-bottom: 4px;
      color: #000000;
      font-size: 16px;
    }

    p {
      letter-spacing: -0.03rem;
      color: #000000;
      font-size: 14px;

      ion-icon {
        color: #000000 !important;
      }
    }
  }

  .detailButton {
    position: absolute !important;
    right: 0px !important;
  }

  .rightInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      letter-spacing: -0.03rem;
    }

    .newmsg {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #d62c03;
      color: #ffffff;
      font-size: 12px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
      font-weight: 600;
    }

    .hidden {
      opacity: 0;
    }
  }

  ion-button {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 0.75rem;
    --padding-end: 0.75rem;
    margin-top: -0.2rem;
    color: #000000;
  }
}

.noResults {
  color: black;
  padding: 0 1rem 1rem;
}
