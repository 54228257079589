.faq {
  color: #eeeef2;
  .content {
    background-color: #eeeef2;
    .headerwrap {
      margin-top: 50px !important;
      --background: #eeeef2;
      padding-bottom: 15px;
    }
    ion-item {
      --padding-start: 16px;
      --inner-padding-end: 5px;
      color: #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading {
        color: #222460;
        font-size: 28px;
        font-weight: 700;
      }
      .back {
        font-size: 14px;
        cursor: pointer;
      }
    }
    .gridlist {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      padding: 10px;
      background-color: #ffffff;
      color: #000000;
      border-radius: 8px;
      min-height: 100px;
      .question {
        font-weight: 600;
        margin: 15px 0px;
      }
      .answer {
        margin: 10px 10px;
        padding-bottom: 5px;
        margin-left: 15px;
      }
      .linkheading {
        font-weight: 500;
        margin: 10px 0px 0px 0px;
      }
    }
  }
  ion-content {
    --background: #eeeef2;
  }
}
